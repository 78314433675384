<template>
  <div v-loading.fullscreen="loading" class="flex flex-col h-full">
    <div class="flex items-center header-container">
      <div class="flex-grow items-center flex h-full header-container__left">
        <h4 class="font-bold text-lg truncate items-center flex mr-4 h-full">
          {{ $t('barcode.barcode_publish_result') }}
        </h4>
        <h4
          v-if="data.projectName"
          class="font-bold text-base truncate items-center flex mr-4 border-solid border rounded p-1"
        >
          {{ data.projectName }}
        </h4>
        <h4
          v-if="data.barcodeType"
          class="font-bold text-base truncate items-center flex mr-4 border-solid border rounded p-1"
        >
          {{ data?.barcodeTypeDefinition?.name }}
        </h4>
        <h4 class="font-bold text-base truncate items-center flex mr-4 h-full">
          {{ $t('barcodeImport.total_number') }}
        </h4>
        <h4 class="font-bold text-base truncate items-center flex mr-4 border-solid border rounded p-1 px-2">
          {{ data.totalCount }}
        </h4>
        <h4 class="font-bold text-base truncate items-center flex mr-4 h-wwfull">
          {{ $t('barcodeImport.errors_number') }}
        </h4>
        <h4 class="font-bold text-base truncate items-center flex mr-4 border-solid border rounded p-1 px-2">
          {{ data.errorCount }}
        </h4>
      </div>
      <div class="flex header-container__right">
        <el-button
          class="flex justify-center flex-col items-center bg-dark-min btn-back-button ml-3 rounded-full"
          @click="goToImportList"
        >
          <div class="flex flex-col items-center">
            <IconArrowLeft color="#fff" />
            <span>BACK</span>
          </div>
        </el-button>
      </div>
    </div>
    <h4 v-if="data.status === 'error'" class="text-sm text-red-500 items-center flex mr-4 py-2">
      {{ errorBarcodeImportMessage }}
    </h4>
    <el-tabs>
      <!--- ===================================== SUCCESS TAB ===================================== -->
      <el-tab-pane :label="$t('barcodeImport.success')">
        <div class="flex justify-between pb-3 pt-2 header-bar items-center rounded-t-xl mt-1">
          <div class="ml-4 content-end font-bold text-base">
            {{ $t('total_barcode') }}: {{ data.successCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </div>
          <div>
            <el-dropdown placement="bottom-end">
              <el-button
                class="rounded-l-md mr-4"
                :type="isDownloadBtnDisabled ? 'primary' : 'default'"
                size="medium"
                :disabled="isDownloadBtnDisabled"
              >
                {{ $t('barcodeImport.download') }}
                <el-icon class="el-icon-arrow-down"></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="download('pdf')">
                    <div class="flex flex-row items-center px-3">
                      <span>{{ $t('barcodeImport.pdf_format') }}</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item @click="download('csv')">
                    <div class="flex flex-row items-center px-3">
                      <span>{{ $t('barcodeImport.csv_format') }}</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <div v-if="data?.barcodeCreationResults?.length > 0" class="flex-grow flex flex-col">
          <div class="flex-grow table-container">
            <el-table
              :data="data.barcodeCreationResults"
              class="w-full"
              header-cell-class-name="custom-table__header-cell"
              :cell-class-name="tableCellClassName"
              border
              :empty-text="$t('empty')"
              highlight-current-row
              @current-change="onRowClick"
            >
              <el-table-column
                class-name="bg-red-min"
                v-if="data.errorCount > 0"
                prop="barcodeId"
                :label="$t('result')"
              >
                <template v-slot="scope">
                  <span class="text-gray-900"> {{ formatErrorMessage(scope.row.errorMessages) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="220"
                v-if="data?.barcodeCreationResults.some((val) => val.barcodeId)"
                prop="barcodeId"
                :label="$t('barcode.id')"
              />
              <el-table-column prop="externalId" :label="$t('barcode.externalId')" />
              <el-table-column prop="trackingNumber" :label="$t('barcode.trackingNumber')" />
              <el-table-column prop="origin" :label="$t('barcode.origin')">
                <template v-slot="scope">
                  <div v-if="scope.row.origin" class="flex flex-col">
                    <span>{{ scope.row.origin?.postalCode || '' }}</span>
                    <div>
                      <span>{{ scope.row.origin?.prefecture || '' }}</span>
                      <span>{{ scope.row.origin?.address1 || '' }}</span>
                      <span>{{ `${scope.row.origin?.address2 || ''} ${scope.row.origin?.companyName || ''}` }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="destination" :label="$t('barcode.destination')">
                <template v-slot="scope">
                  <div v-if="scope.row.destination" class="flex flex-col">
                    <span>{{ scope.row.destination?.postalCode || '' }}</span>
                    <div>
                      <span>{{ scope.row.destination?.prefecture || '' }}</span>
                      <span>{{ scope.row.destination?.address1 || '' }}</span>
                      <span>{{
                        `${scope.row.destination?.address2 || ''} ${scope.row.destination?.companyName || ''}`
                      }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="dimension" :label="$t('barcode.dimension')">
                <template v-slot="scope">
                  {{ formatDimension(scope.row.dimension) }}
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="justify-center flex flex-row my-16 paging-container">
            <el-pagination
              layout="prev, pager, next"
              :total="data.totalCount"
              :page-size="itemsPerPage"
              :current-page="currentPageNum"
              @current-change="currentPageChange($event)"
              background
              class="custom-pagination"
            />
          </div>

          <BarcodePDF
            ref="barcodePDFComponent"
            :barcodes="barcodes"
            :project="project"
            :barcodeType="data.barcodeType"
          />
        </div>

        <template v-else>
          <ItemNotFound class="mt-56" :content="$t('empty')" />
        </template>
      </el-tab-pane>
      <!--- ===================================== ERROR TAB ===================================== -->
      <el-tab-pane :label="$t('barcodeImport.error')">
        <div class="flex justify-between pb-3 pt-2 header-bar items-center rounded-t-xl mt-1">
          <div class="ml-4 content-end font-bold text-base">
            {{ $t('total_barcode') }}: {{ errorData.errorCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </div>
          <div>
            <el-select
              v-model="BCFormat"
              :placeholder="$t('barcodeImport.download')"
              class="w-32 mr-4"
              size="medium"
              @change="download($event)"
              :disabled="isDownloadBtnDisabled"
            >
              <el-option :value="'pdf'" :label="$t('barcodeImport.pdf_format')" />
              <el-option :value="'csv'" :label="$t('barcodeImport.csv_format')" />
            </el-select>
          </div>
        </div>
        <div v-if="errorData?.barcodeCreationResults?.length > 0" class="flex-grow flex flex-col">
          <div class="flex-grow table-container">
            <el-table
              :data="errorData.barcodeCreationResults"
              class="w-full"
              header-cell-class-name="custom-table__header-cell"
              :cell-class-name="tableCellClassName"
              border
              :empty-text="$t('empty')"
              highlight-current-row
              @current-change="onRowClick"
            >
              <el-table-column
                class-name="bg-red-min"
                v-if="errorData.errorCount > 0"
                prop="barcodeId"
                :label="$t('result')"
              >
                <template v-slot="scope">
                  <span class="text-gray-900"> {{ formatErrorMessage(scope.row.errorMessages) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="220"
                v-if="errorData?.barcodeCreationResults.some((val) => val.barcodeId)"
                prop="barcodeId"
                :label="$t('barcode.id')"
              />
              <el-table-column prop="externalId" :label="$t('barcode.externalId')" />
              <el-table-column prop="trackingNumber" :label="$t('barcode.trackingNumber')" />
              <el-table-column prop="origin" :label="$t('barcode.origin')">
                <template v-slot="scope">
                  <div v-if="scope.row.origin" class="flex flex-col">
                    <span>{{ scope.row.origin?.postalCode || '' }}</span>
                    <div>
                      <span>{{ scope.row.origin?.prefecture || '' }}</span>
                      <span>{{ scope.row.origin?.address1 || '' }}</span>
                      <span>{{ `${scope.row.origin?.address2 || ''} ${scope.row.origin?.companyName || ''}` }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="destination" :label="$t('barcode.destination')">
                <template v-slot="scope">
                  <div v-if="scope.row.destination" class="flex flex-col">
                    <span>{{ scope.row.destination?.postalCode || '' }}</span>
                    <div>
                      <span>{{ scope.row.destination?.prefecture || '' }}</span>
                      <span>{{ scope.row.destination?.address1 || '' }}</span>
                      <span>{{
                        `${scope.row.destination?.address2 || ''} ${scope.row.destination?.companyName || ''}`
                      }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="dimension" :label="$t('barcode.dimension')">
                <template v-slot="scope">
                  {{ formatDimension(scope.row.dimension) }}
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="justify-center flex flex-row my-16 paging-container">
            <el-pagination
              layout="prev, pager, next"
              :total="errorData.errorCount"
              :page-size="itemsPerPage"
              :current-page="currentErrorPage"
              @current-change="currentErrorPageChange($event)"
              background
              class="custom-pagination"
            />
          </div>

          <BarcodePDF
            ref="barcodePDFComponent"
            :barcodes="barcodes"
            :project="project"
            :barcodeType="errorData.barcodeType"
          />
        </div>

        <template v-else>
          <ItemNotFound class="mt-56" :content="$t('empty')" />
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="ts">
import BarcodePDF from '@/components/barcodeImport/BarcodePDF.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconArrowLeft from '@/components/svg/IconArrowLeft.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import { SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { exportBarCode, fetchProjectById, getBarcodeImport, getBarcodeImportDetail } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import { maska } from 'maska'
import { EBarcodePublishStatus, EExportType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IBarcodeCreationResult,
  IBarcodeDefinitionType,
  IBarcodeImportDetail,
  IBarcodeList,
  ICommonSearch,
  IDimension,
  IProject,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Prop, ProvideReactive } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader, IconDownload, BarcodePDF, IconArrowLeft },
  directives: { maska },
  name: 'BarcodeImportDetail',
})
export default class BarcodeImportDetail extends Mixins(DataTableMixin) {
  @ProvideReactive() projectVersion = 0
  @Prop({ default: '' }) readonly projectCode: string = ''

  loading = false

  BCFormat = ''

  DEFAULT_DATA = {
    projectId: '',
    barcodeType: '',
    operationType: '',
    status: '',
    successCount: 0,
    errorCount: 0,
    totalCount: 0,
    id: '',
    barcodeCreationResults: [],
  }

  data: IBarcodeImportDetail & {
    projectName?: string
    barcodeTypeDefinition?: IBarcodeDefinitionType
  } = { ...this.DEFAULT_DATA }

  errorData: IBarcodeImportDetail & {
    projectName?: string
    barcodeTypeDefinition?: IBarcodeDefinitionType
  } = { ...this.DEFAULT_DATA }

  dataSearch = {} as ICommonSearch
  project = {} as IProject
  barcodes = [] as Array<IBarcodeList>

  currentErrorPage = 1

  get isDownloadBtnDisabled(): boolean {
    return this.loading || this.data.errorCount > 0 || this.data?.status !== EBarcodePublishStatus.COMPLETED
  }

  get errorBarcodeImportMessage() {
    return this.data?.errorMessage ? this.data?.errorMessage : this.$t('barcodeImport.errors_message')
  }

  tableCellClassName(object: { row: IBarcodeCreationResult; rowIndex: number; columnIndex: number }) {
    if (object.row.errorMessages.length > 0) {
      return 'custom-table__cell cursor-pointer error-row'
    }
    return 'custom-table__cell cursor-pointer'
  }

  goToImportList() {
    this.$router.push({
      name: 'barcodePublish',
      query: {
        projectCode: this.projectCode,
      },
    })
  }

  formatDimension(dimension: IDimension) {
    if (dimension) {
      const arrString = []
      const arrResult = []
      if (dimension?.width) {
        arrString.push(`${this.$t('width')} ${dimension?.width} cm `)
      }
      if (dimension?.depth) {
        arrString.push(`${this.$t('depth')} ${dimension?.depth} cm `)
      }
      if (dimension?.height) {
        arrString.push(`${this.$t('height')} ${dimension?.height} cm `)
      }

      if (arrString.length > 0) {
        arrResult.push(arrString.join(' x '))
      }
      if (dimension?.weight) {
        arrResult.push(`${this.$t('weight')} ${dimension?.weight} kg `)
      }
      return arrResult.join(', ')
    }

    return ''
  }

  formatErrorMessage(errorArr: string[]) {
    return errorArr.join(' ,')
  }

  onRowClick(barcode: IBarcodeCreationResult) {
    if (!barcode.barcodeId) return
    this.$router.push({
      name: 'barcodeView',
      params: { id: barcode.barcodeId },
    })
  }

  download(type: 'pdf' | 'csv') {
    const obj = {
      pdf: this.downloadPDF,
      csv: this.downloadCSV,
    }
    obj[type]()
    this.BCFormat = ''
  }

  async downloadCSV() {
    try {
      this.loading = true
      const payload = {
        projectCode: this.currentSelectProject?.code || '',
        barcodeImportId: this.barcodeImportId,
      }
      await exportBarCode(payload)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (e) {
      openMessage(e as string, 'error')
    } finally {
      this.loading = false
    }
  }

  async downloadPDF() {
    try {
      this.loading = true
      const [projectResult, result] = await Promise.all([
        fetchProjectById(this.data.projectId),
        getBarcodeImportDetail(this.barcodeImportId),
      ])
      this.barcodes = result.results
      this.project = projectResult.project
      this.projectVersion = this.project?.version ?? 0
      this.$refs.barcodePDFComponent.showComfirmPopup()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  get barcodeImportId() {
    return this.$route.params.id || ''
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    await this.fetchList()
  }

  async currentErrorPageChange(pageNum: number) {
    this.currentErrorPage = pageNum
    await this.fetchErrorList()
  }

  async fetchList() {
    this.loading = true
    this.isShowTable = false
    this.redirectIndex()

    this.data =
      (await getBarcodeImport({
        id: this.barcodeImportId,
        skip: (this.currentPageNum - 1) * this.itemsPerPage,
        count: this.itemsPerPage,
        type: 'success',
      })) || {}

    setTimeout(() => {
      this.isShowTable = true
      this.loading = false
    })
  }

  async fetchErrorList() {
    this.loading = true
    this.isShowTable = false

    this.errorData =
      (await getBarcodeImport({
        id: this.barcodeImportId,
        skip: (this.currentErrorPage - 1) * this.itemsPerPage,
        count: this.itemsPerPage,
        type: 'error',
      })) || {}

    setTimeout(() => {
      this.isShowTable = true
      this.loading = false
    })
  }

  async created() {
    await this.fetchList()
    await this.fetchErrorList()
  }
}
</script>
<style lang="scss" scoped>
.el-table .error-row {
  background-color: #ffbbbb !important;
}
.el-table .completed-row {
  background-color: #e0e0e0 !important;
}

.el-table .warning-row {
  background-color: rgba(255, 255, 135, 0.836) !important;
}
.table-container:deep() {
  &.el-table {
    &__header {
      thead {
        tr:first-child {
          th:first-child {
            border-top-left-radius: 0px !important;
          }
          th:last-child {
            border-top-right-radius: 0px !important;
          }
        }
      }
    }
  }
}
.btn-back {
  width: 48px;
  height: 48px;
  background: #979797;
  font-size: 10px;
  color: #ffffff;
  font-weight: 500;
  &:hover {
    background: #2e7cf6;
  }

  svg {
    path {
      stroke: #ffffff;
      fill: #ffffff;
    }
  }
}

.header-bar {
  background-color: #e0e0e0;
}
</style>
